/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "configuration-files",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#configuration-files",
    "aria-label": "configuration files permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Configuration Files"), "\n", React.createElement(_components.p, null, "SVGR supports project configuration files for SVGR, SVGO and Prettier."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "svgr",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#svgr",
    "aria-label": "svgr permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SVGR"), "\n", React.createElement(_components.p, null, "SVGR uses ", React.createElement(_components.a, {
    href: "https://github.com/davidtheclark/cosmiconfig"
  }, "cosmiconfig"), " for configuration file support. This means you can configure SVGR via:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A ", React.createElement(_components.code, null, ".svgrrc"), " file, written in YAML or JSON, with optional extensions: .yaml/.yml/.json/.js."), "\n", React.createElement(_components.li, null, "A ", React.createElement(_components.code, null, "svgr.config.js"), " file that exports an object."), "\n", React.createElement(_components.li, null, "A \"svgr\" key in your package.json file."), "\n"), "\n", React.createElement(_components.p, null, "The configuration file will be resolved starting from the location of the file being formatted, and searching up the file tree until a config file is (or isn't) found."), "\n", React.createElement(_components.p, null, "The options to the configuration file are the same as the API options."), "\n", React.createElement(_components.h2, {
    id: "example",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example",
    "aria-label": "example permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example"), "\n", React.createElement(_components.p, null, "JSON:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"icon\": true,\n  \"expandProps\": false\n}\n")), "\n", React.createElement(_components.p, null, "JS:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// .svgrrc.js\nmodule.exports = {\n  icon: true,\n  expandProps: false,\n}\n")), "\n", React.createElement(_components.p, null, "YAML:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-yml"
  }, "# .svgrrc\nicon: true\nexpandProps: false\n")), "\n", React.createElement(_components.h2, {
    id: "svgo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#svgo",
    "aria-label": "svgo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "SVGO"), "\n", React.createElement(_components.p, null, "The recommended way to configure SVGO for SVGR is to use ", React.createElement(_components.a, {
    href: "https://github.com/svg/svgo/blob/main/README.md#configuration"
  }, React.createElement(_components.code, null, "svgo.config.js or svgo.config.cjs")), "."), "\n", React.createElement(_components.p, null, "Even if it is not recommended, you can also use ", React.createElement(_components.code, null, "svgoConfig"), " option to specify your SVGO configuration. ", React.createElement(_components.code, null, "svgoConfig"), " has precedence on ", React.createElement(_components.code, null, "svgo.config.js"), "."), "\n", React.createElement(_components.h2, {
    id: "prettier",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prettier",
    "aria-label": "prettier permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prettier"), "\n", React.createElement(_components.p, null, "The recommended way to configure Prettier for SVGR is to use ", React.createElement(_components.a, {
    href: "https://prettier.io/docs/en/configuration.html"
  }, React.createElement(_components.code, null, ".prettierrc")), ". It is fully supported in ", React.createElement(_components.a, {
    href: "https://prettier.io/docs/en/configuration.html"
  }, "all formats available"), " and it is relative to the transformed SVG file."), "\n", React.createElement(_components.p, null, "Even if it is not recommended, you can also use ", React.createElement(_components.code, null, "prettierConfig"), " option to specify your Prettier configuration. ", React.createElement(_components.code, null, "prettierConfig"), " has precedence on ", React.createElement(_components.code, null, ".prettierrc"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
